










import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';

@Component({})
export default class AddToSlackCallback extends Vue {
  url: string | null = null;
  redirect_url: string | null = null;
  beforeMount() {
    if (this.$route.query.code) {
      this.$apollo.mutate({
        mutation: gql`
                        mutation ConnectSlack($code: String!, $board_id: ID!) {
                            connectSlack(code: $code, board_id: $board_id) {
                                id
                                pid
                            }
                        }`,
        variables: {
          code: this.$route.query.code,
          board_id: this.$store.state.board.id,
        },
      })
        .then(() => {
          window.close();
        })
        .catch(() => {
       
        })
        .finally(() => {
        
        });
    }

    if (this.$route.query.redir) {
      this.redirect_url = window.location.origin +'/slackconnect/callback';
      this.url = 'https://slack.com/oauth/v2/authorize?scope=incoming-webhook,commands&client_id=732552591783.995845065110&redirect_uri='+ this.redirect_url;
      window.location.href = this.url;
    }
  }
}
